import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgDirectDebit from "../images/direct-debit.jpg"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Direct Debit support arrives on ShuttleID" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Direct Debit support arrives on ShuttleID</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Direct Debit support arrives on ShuttleID</h1>
              </div>

              <div>

                <div className="article">
                  {/* <div className='headline-block article-inner'>
                    <h1 className="blog-headline">Direct Debit support arrives on ShuttleID</h1>
                    <p className="subheadline">Parents are now able to pay for bus tickets with the flexibility of Direct Debit</p>
                  </div> */}
                  <div style={{ marginTop: '50px' }} className='article-inner'>
                    <p>Operators that need to offer flexible payments to parents will be pleased to hear that ShuttleID now offers Direct Debit as a method of payment.</p>
                    <p>The integration with GoCardless means that operators can setup a ticket for sale and the ShuttleID platform will take care of:</p>
                    <ul>
                      <li>End user setup of Direct Debit mandate</li>
                      <li>Payment of initial deposit (if required)</li>
                      <li>Setup of Direct Debit subscription to start and end automatically</li>
                    </ul>
                    <p>When scaled to hundreds of passengers, this accounts for significant savings in administration. The whole process becomes automated and removes the burden from operations.</p>
                    <h2>How does it work?</h2>
                    <p><img src={imgDirectDebit} alt='Direct debit' /></p>
                    <p>After connecting your GoCardless account with ShuttleID, you can list tickets for sale with Direct Debit as the payment method. The ticket configuration allows you to receive a deposit, and to configure the start/end dates of Direct Debit subscription.</p>
                    <p>When first time customers buy your listed ticket, they'll be prompted to setup a Direct Debit mandate with you. It's a quick process that's done entirely online.</p>
                    <p>Customers will then be able to proceed with their purchase, with any initial deposits taken immediately using the Direct Debit mandate, and the subscription setup for the configured start/end dates.</p>
                    <p>For users with an existing mandate, payment will reach the operator's bank account within 5 working days. <a className="link" href="https://support.gocardless.com/hc/en-gb/articles/210535689-Bacs-UK-Payment-Timings">More information on timing</a> is available from GoCardless.</p>
                    <h2>What happens if Direct Debits are cancelled?</h2>
                    <p>In the scenario a Direct Debit is cancelled or failed, operators will receive an alert. Using this information operators have the option the work with the parents to restore the Direct Debit or to void their tickets if necessary.</p>
                    <p>The advantage of using the ShuttleID platform means the ticket validity is reflected immediately, and the on board ticket scanner will deny access to travel.</p>
                    <h2>How will PSD2 affect Direct Debit?</h2>
                    <p>Another advantage to using Direct Debit as a method of payment, is that it is unaffected by the <Link className="link" to='/is-your-coach-company-ready-for-psd2-sca'>looming PSD2/SCA deadline</Link>.</p>
                    <p>For operators that are concerned about the potential impact of PSD2 on conversions, they may want to consider looking at Direct Debit as an alternative.</p>
                    <div style={{ background: 'rgb(229, 243, 255)', padding: '1em', marginBottom: '1em'}}>
                      <p style={{margin:0}}><strong>ShuttleID supports recurring payments.</strong> Struggling to track payment from parents? We automate payment handling and enforce ticket validity without the admin overhead or compliance (SCA) worries. <a className="link" href='mailto:info@shuttleid.uk?subject=Recurring%20payments'>Find out more</a>.</p>
                    </div>
                  </div>
                </div>

              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
